var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.loaded
    ? _c("div", { staticClass: "preview-doc" }, [
        _vm.image
          ? _c(
              "div",
              {
                staticClass: "preview-button",
                on: {
                  click: function ($event) {
                    _vm.modalVisible = true
                  },
                },
              },
              [
                _c("span", [
                  _vm._v(_vm._s(_vm.$t("widget.ts.viewUploadedDocument"))),
                ]),
                _c("i", { staticClass: "fas fa-eye view-document" }),
              ]
            )
          : _c("div", { staticClass: "preview-button not-found" }, [
              _c("span", [_vm._v(_vm._s(_vm.$t("widget.ts.noDocumentFound")))]),
            ]),
        _vm.modalVisible
          ? _c(
              "div",
              {
                staticClass: "modal",
                on: {
                  click: function ($event) {
                    if ($event.target !== $event.currentTarget) return null
                    _vm.modalVisible = false
                  },
                },
              },
              [
                _c("i", {
                  staticClass: "far fa-times close-modal",
                  on: {
                    click: function ($event) {
                      _vm.modalVisible = false
                    },
                  },
                }),
                _c("img", { attrs: { src: _vm.image, alt: _vm.image } }),
              ]
            )
          : _vm._e(),
      ])
    : _c("content-loader", {
        staticClass: "load-preview",
        attrs: { width: 155, height: 23 },
      })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }