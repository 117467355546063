import { TariffChangeWidgetBsw } from '@/components/widgets/tariff-change-widget/custom/bsw';
import { TariffChangeWidgetElli } from '@/components/widgets/tariff-change-widget/custom/elli';
import { TariffChangeWidget as TariffChangeWidgetQcells } from '@/components/widgets/tariff-change-widget/custom/qcells';
import { TariffChangeWidgetWdenergy } from '@/components/widgets/tariff-change-widget/custom/wdenergy';
import { TariffChangeWidgetWdenergyUs } from '@/components/widgets/tariff-change-widget/custom/wdenergy-us';
import { TariffChangeWidgetDspFlex } from '@/components/widgets/tariff-change-widget/custom/dsp-flex';
import { TariffChangeWidgetProkon } from '@/components/widgets/tariff-change-widget/custom/prokon';
import { TariffChangeWidget } from '@/components/widgets/tariff-change-widget/tariff-change-widget';

let widget;

if (process.env.VUE_APP_ENVIRONMENT === 'bsw') {
    widget = TariffChangeWidgetBsw;
} else if (process.env.VUE_APP_ENVIRONMENT === 'elli') {
    widget = TariffChangeWidgetElli;
} else if (process.env.VUE_APP_ENVIRONMENT === 'qcells') {
    widget = TariffChangeWidgetQcells;
} else if (process.env.VUE_APP_ENVIRONMENT === 'sonnenwind') {
    widget = TariffChangeWidgetWdenergy;
} else if (process.env.VUE_APP_ENVIRONMENT === 'wdenergy') {
    widget = TariffChangeWidgetWdenergy;
} else if (process.env.VUE_APP_ENVIRONMENT === 'wdenergy-us') {
    widget = TariffChangeWidgetWdenergyUs;
} else if (process.env.VUE_APP_ENVIRONMENT?.includes('dsp-')) {
    widget = TariffChangeWidgetDspFlex;
} else if (process.env.VUE_APP_ENVIRONMENT === 'prokon') {
    widget = TariffChangeWidgetProkon;
} else {
    widget = TariffChangeWidget;
}
export { widget as TariffChangeWidget };
