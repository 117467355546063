import Base from '@/mixins/base';
import { default as Component } from 'vue-class-component';
import { ContentLoader } from 'vue-content-loader';
import WithRender from './tariff-details-widget.html';

import { Watch } from 'vue-property-decorator';

import SERVICES from '../../../container/Services';
import IocContainer from '../../../container/IocContainer';
import Format from '../../../interfaces/Format';
import moment from 'moment';
import Tracking from '@/interfaces/Tracking';
import Commodity from '@/interfaces/Commodity';
import { TranslateResult } from 'vue-i18n';
import * as Sentry from '@sentry/browser';
import Contracts from '@/interfaces/Contracts';

const formatProvider = IocContainer.get<Format>(SERVICES.FORMAT);
const trackingProvider = IocContainer.get<Tracking>(SERVICES.TRACKING);
const commodityProvider = IocContainer.get<Commodity>(SERVICES.COMMODITY);
const contractProvider = IocContainer.get<Contracts>(SERVICES.CONTRACTS);

@WithRender
@Component({
    filters: {
        euroFull: formatProvider.euroFull(),
        euro: formatProvider.euro(),
        date: formatProvider.date(),
        monthYear: formatProvider.monthYear(),
    },
    components: {
        ContentLoader,
    },
})
export class TariffDetailsWidget extends Base {
    protected elements = {
        bsw: [
            'contract-details-label',
            'hands-helping-icon',
            'show-fixedPrice',
            'show-period-info',
            'contract-number-in-td',
            'contract-productname-in-td',
            'counter-number-in-td',
            'market-location-identifier-in-td',
            'hide-tariff-overview',
            'hide-contract-move',
            'hide-tariff-change',
            'align-title',
            'non-padding',
            'show-agb-links',
        ],
        qcells: ['hide-nextCancellationDate'],
        velbert: ['close-button-hidden'],
        shell: ['close-button-hidden'],
        sachsenenergie: [
            'show-nextCancellationDate-extended',
            'product-name-instead-of-runtime',
            'contract-details-label',
            'show-term-of-contract',
            'tariff-details-offset',
            'tariff-details-button-link',
            'tariff-details-button-link-arrow',
            'allow-cancellation-wait-and-active',
            'hide-cancellation-reason',
            'no-fullPrice',
        ],
        wdenergy: [
            'close-button-hidden',
            'show-cancellation-link',
            'hide-cancellation-button',
        ],
        'wdenergy-us': [
            'close-button-hidden',
            'show-cancellation-link',
            'hide-cancellation-button',
        ],
        schkeuditz: ['counter-number-in-td', 'show-fixedPrice'],
        prokon: [
            'address-details',
            'close-button-hidden',
            'hide-tariff-overview',
            'show-tariff-overview',
            'hide-contract-move',
            'show-contract-move',
            'hide-cancellation-button',
            'show-cancellation-button',
            'hide-tariff-change',
        ],
        elli: [
            'close-button-hidden',
            'show-cancellation-link',
            'hide-cancellation-button',
            'hide-tariff-change',
        ],
        eoptimum: [
            'hide-contract-move',
            'hide-cancellation-button',
            'hide-tariff-change',
            'hide-revoke-button',
            'close-button-hidden',
            'no-fullPrice',
            'hide-base-price',
            'hide-working-price',
            'start-date-in-td',
            'next-cancellation-date-in-td',
        ],
        'dsp-ahrensburg': ['close-button-hidden'],
        hsg: [
            'no-fullPrice',
            'hide-nextCancellationDate',
            'show-period-info-hsg',
            'close-button-hidden',
        ],
        wechselstrom: [
            'no-fullPrice',
            'hide-nextCancellationDate',
            'show-period-info-hsg',
            'close-button-hidden',
        ],
    };

    protected created(): void {
        if (
            this.$store.state.contracts.contractId &&
            [
                'velbert',
                'sachsenenergie',
                'shell',
                'wdenergy',
                'prokon',
            ].indexOf(this.currentClient || '') > -1
        ) {
            this.$store.dispatch(
                'tariff/overview',
                this.$store.state.contracts.contractId
            );
            this.$store.dispatch(
                'tariff/details',
                this.$store.state.contracts.contractId
            );
        }
        if (this.tariffOverviewAllowed) {
            this.$store.dispatch(
                'tariff/list',
                this.$store.state.contracts.contractId
            );
        }
        if (this.tariffChangeAllowed) {
            this.$store.dispatch(
                'tariff/forChange',
                this.$store.state.contracts.contractId
            );
        }
    }

    protected processCancellationLink() {
        if (this.currentClient === 'elli') {
            if (location.hash === '') {
                location.hash = 'cancellation';
                setTimeout(() => {
                    const element = document.querySelector('#cancellation');
                    if (element) {
                        element.scrollIntoView();
                    }
                }, 1);
            } else {
                location.hash = '';
            }
        }
        this.$emit('toggleWidget', 'showCancellationWidget');
    }

    protected mounted(): void {
        trackingProvider.showTariffDetails();
    }

    protected destroyed(): void {
        trackingProvider.hideTariffDetails();
    }

    get tariff(): Record<any, any> {
        return this.$store.getters['tariff/getState'](
            this.$store.state.contracts.contractId
        ).tariff;
    }

    get contract(): Record<any, any> {
        if (
            this.$store.getters['tariff/getState'](
                this.$store.state.contracts.contractId
            )
        ) {
            return this.$store.getters['tariff/getState'](
                this.$store.state.contracts.contractId
            ).contract;
        }
        return {};
    }

    get contractMoveAllowed(): boolean {
        if (this.currentClient === 'hsg') {
            return (
                this.contract.statusCode <= 6999 &&
                this.contract.statusCode >= 4000
            );
        }
        return (
            this.$store.state.settings.contractMove &&
            this.contract.relocationPossible
        );
    }

    get contractCancellationAllowed(): boolean {
        if (this.currentClient && this.currentClient.indexOf('elli') >= 0) {
            return this.isContractInDelivery(this.contract);
        }

        if (this.displayElement('allow-cancellation-wait-and-active')) {
            if (
                this.contract.statusCode <= 5000 &&
                this.contract.statusCode >= 4000
            ) {
                return (
                    this.$store.state.settings.contractCancellation &&
                    !this.contractInCancellation &&
                    !this.contractCancelled
                );
            }
            return false;
        } else {
            return (
                this.$store.state.settings.contractCancellation &&
                !this.contractInCancellation &&
                !this.contractCancelled
            );
        }
    }

    get contractInRevocation(): boolean {
        return (
            this.contract.revokeAt !== null &&
            this.contract.fillingDateRevocation !== null
        );
    }

    get tariffChangeAllowed(): boolean {
        return this.$store.state.settings.tariffChange;
    }

    get tariffChangeAvailable(): boolean {
        return (
            this.$store.getters['tariff/getState'](
                this.$store.state.contracts.contractId
            ).changeList.length > 0
        );
    }

    get tariffOverviewAllowed(): boolean {
        return this.$store.state.settings.tariffOverview;
    }

    get depositLoaded() {
        return this.$store.getters['payment/getState'](
            this.$store.state.contracts.contractId
        ).deposit.loaded;
    }

    get fixedPriceMonths(): string {
        if (
            !this.$store.getters['tariff/getState'](
                this.$store.state.contracts.contractId
            ).tariff.fixedPriceMonths
        ) {
            return '';
        }
        return (
            this.$store.getters['tariff/getState'](
                this.$store.state.contracts.contractId
            ).tariff.fixedPriceMonths +
            ' ' +
            this.$t('widget.td.fixedPrice.months')
        );
    }

    get contractBankDetails(): any {
        return this.contract.bankingDetails.inout[0] ?? {};
    }

    @Watch('tariffChangeAllowed')
    protected onTariffChangeAllowedChange(newVal): void {
        if (newVal) {
            this.$store.dispatch(
                'tariff/forChange',
                this.$store.state.contracts.contractId
            );
        }
    }

    @Watch('tariffOverviewAllowed')
    protected onTariffOverviewAllowedChange(newVal): void {
        if (newVal) {
            this.$store.dispatch(
                'tariff/list',
                this.$store.state.contracts.contractId
            );
        }
    }

    get displayRuntime(): boolean {
        return (
            this.currentClient !== 'eoptimum' && this.contract.statusCode > 3999
        );
    }

    get endDate(): string {
        return this.contract.endDate;
    }

    get nextCancellationDate(): string {
        return this.contract.nextCancellationDate;
    }

    get cancellationPeriod(): string {
        return this.$store.getters['tariff/getState'](
            this.$store.state.contracts.contractId
        ).tariff.cancellationPeriod;
    }

    get product(): Record<any, any> {
        return this.$store.getters['tariff/getState'](
            this.$store.state.contracts.contractId
        ).product;
    }

    get revocationDate(): string {
        return moment(new Date()).format('YYYY-MM-DD');
    }

    get contractStartDate(): string {
        return this.$store.getters['tariff/getState'](
            this.$store.state.contracts.contractId
        ).contract.startDate;
    }

    get showRevocationWidget(): boolean {
        const date = moment(this.contractStartDate, 'YYYY-MM-DD').add(
            14,
            'days'
        );
        return (
            this.revocationDate <= date.toISOString() &&
            this.$store.state.settings.revocationProcessActive
        );
    }

    get commodity(): TranslateResult {
        return this.$t(
            commodityProvider.getCommodityType(
                this.$store.getters['tariff/getState'](
                    this.$store.state.contracts.contractId
                ).contract,
                'tariff-details'
            )
        );
    }

    get commodityPrice(): TranslateResult {
        return this.$t(
            commodityProvider.getCommodityTypeWorkingPrice(
                this.$store.getters['tariff/getState'](
                    this.$store.state.contracts.contractId
                ).contract
            )
        );
    }

    get isDoubleTariff(): boolean {
        return (
            this.tariff.workingPrices && this.tariff.workingPrices.length == 2
        );
    }

    get fullPrice(): number {
        let workingPrice = 0;
        if (
            this.tariff.workingPrices &&
            this.tariff.workingPrices.length == 2
        ) {
            this.tariff.workingPrices.forEach((item) => {
                // ((usage * 60%) * priceHT )
                if (item.registerName === 'HT') {
                    workingPrice +=
                        this.contract.usage *
                        0.6 *
                        (parseFloat(item.workingPriceBrutto) / 100);
                }
                if (item.registerName === 'NT') {
                    workingPrice +=
                        this.contract.usage *
                        0.4 *
                        (parseFloat(item.workingPriceBrutto) / 100);
                }
            });

            return workingPrice;
        }
        if (this.tariff.workingPrices) {
            this.tariff.workingPrices.forEach((item) => {
                workingPrice += parseFloat(item.workingPriceBrutto);
            });
        }

        return (
            this.contract.usage * (workingPrice / 100) +
            parseFloat(this.tariff.basePrice)
        );
    }

    private downloadArchiveFile(id) {
        const title = this.getOnlyText(this.currentProduct.cmsAgb);
        contractProvider
            .downloadArchiveFile(
                this.$store.state.contracts.contractId,
                id,
                title
            )
            .then(
                async (response) => {
                    const isJsonBlob = (data) =>
                        data instanceof Blob &&
                        data.type === 'application/json';
                    const responseData =
                        isJsonBlob(response?.data) &&
                        typeof response?.data?.text !== 'undefined'
                            ? await response?.data?.text()
                            : response?.data || {};
                    const responseJson =
                        typeof responseData === 'string'
                            ? JSON.parse(responseData)
                            : responseData;
                    if (
                        responseJson &&
                        responseJson.success &&
                        responseJson.pdf
                    ) {
                        trackingProvider.downloadCustomerCommunication(title);
                        this.saveBlobAsFile(
                            responseJson.pdf,
                            responseJson.title + '.pdf',
                            'application/pdf'
                        );
                        return;
                    }
                },
                (error) => {
                    Sentry.captureException(new Error(error));
                }
            );
    }
}
