import { ChangePaymentDataWidget } from '@/components/widgets/change-payment-data-widget/change-payment-data-widget';
import { ChangePaymentDataWidgetEts } from '@/components/widgets/change-payment-data-widget/custom/ets';
import { ChangePaymentDataWidgetVelbert } from '@/components/widgets/change-payment-data-widget/custom/velbert';
import { ChangePaymentDataWidgetElli } from '@/components/widgets/change-payment-data-widget/custom/elli';
import { ChangePaymentDataWidgetElliThg } from '@/components/widgets/change-payment-data-widget/custom/elli-thg';
import { ChangePaymentDataWidgetElliThgDealer } from '@/components/widgets/change-payment-data-widget/custom/elli-thg-dealer';

let widget;

if (process.env.VUE_APP_ENVIRONMENT === 'elli-thg-dealer') {
    widget = ChangePaymentDataWidgetElliThgDealer;
} else if (process.env.VUE_APP_ENVIRONMENT?.includes('elli-thg')) {
    widget = ChangePaymentDataWidgetElliThg;
} else if (process.env.VUE_APP_ENVIRONMENT?.includes('elli')) {
    widget = ChangePaymentDataWidgetElli;
} else if (process.env.VUE_APP_ENVIRONMENT === 'ets') {
    widget = ChangePaymentDataWidgetEts;
} else if (process.env.VUE_APP_ENVIRONMENT === 'velbert') {
    widget = ChangePaymentDataWidgetVelbert;
} else {
    widget = ChangePaymentDataWidget;
}

export { widget as ChangePaymentDataWidget };
