export const logoMap = {
    bsw: '/img/evus/bsw/logo-2.svg',
    qcells: '/img/evus/qcells/logo.png',
    buehl: '/img/evus/buehl/logo.svg',
    schkeuditz: '/img/evus/schkeuditz/logo_white.png',
    prokon: '/img/evus/prokon/logo_white.png',
    powercloud: '/img/evus/powercloud/logo.png',
    velbert: '/img/evus/velbert/logo_white.svg',
    energiemanufaktur: '/img/evus/energiemanufaktur/logo-white.png',
    shell: '/img/evus/shell/logo.svg',
    ele: '/img/evus/ele/logo.svg',
    naturgy: '/img/evus/naturgy/logo_white.png',
    mvv: '/img/evus/mvv/logo.png',
    teag: '/img/evus/teag/logo.svg',
    twl: '/img/evus/twl/logo.svg',
    sonnenwind: '/img/evus/sonnenwind/logo-white.svg',
    wdenergy: '/img/evus/wdenergy/logo_header.png',
    'wdenergy-us': '/img/evus/wdenergy-us/logo_header.png',
    ewii: '/img/evus/ewii/logo_white.svg',
    sachsenenergie: '/img/evus/sachsenenergie/logo_white.svg',
    'eon-dld': '/img/evus/eon-dld/logo.png',
    keppel: '/img/evus/keppel/logo.png',
    elli: '/img/evus/elli/logo_white.svg',
    'elli-thg-audi': '/img/evus/elli-thg-audi/logo_white.png',
    'elli-thg-cupra': '/img/evus/elli-thg-cupra/logo.png',
    'elli-thg-dealer': '/img/evus/elli-thg-dealer/logo_white.svg',
    'elli-thg-vwfs': '/img/evus/elli-thg-vwfs/logo_vwfs_white.svg',
    'elli-thg-skoda': '/img/evus/elli-thg-skoda/logo.png',
    'elli-thg-freenet': '/img/evus/elli-thg-freenet/logo.svg',
    'elli-thg-elli': '/img/evus/elli-thg-elli/logo.svg',
    'elli-thg-man': '/img/evus/elli-thg-man/logo-white.svg',
    schweizstrom: '/img/evus/schweizstrom/logo_red.png',
    engie: '/img/evus/engie/logo_white.svg',
    'dsp-marl': '/img/evus/dsp-marl/logo_white.svg',
    met: '/img/evus/met/logo_white.png',
    andel: '/img/evus/andel/logo_white.png',
    ewz: '/img/evus/ewz/logo_white.png',
    eins: '/img/evus/eins/logo_white.svg',
    mainova: '/img/evus/mainova/logo_white.png',
    rhoen: '/img/evus/rhoen/logo.png',
    norlys: '/img/evus/norlys/logo.png',
    aura: '/img/evus/aura/logo_white.png',
    eoptimum: '/img/evus/eoptimum/logo.svg',
    new: '/img/evus/new/logo.png',
    landsberg: '/img/evus/landsberg/logo_white.svg',
    'powercloud-us': '/img/evus/powercloud-us/logo_white.svg',
    'dsp-ahrensburg': '/img/evus/dsp-ahrensburg/logo.svg',
    'dsp-oie': '/img/evus/dsp-oie/logo_white.svg',
    'dsp-velbert': '/img/evus/dsp-velbert/logo.png',
    'dsp-revulution': '/img/evus/dsp-revulution/logo.svg',
    swm: '/img/evus/swm/logo.svg',
    'powercloud-es': '/img/evus/powercloud-es/logo_white.svg',
    'powercloud-it': '/img/evus/powercloud-it/logo_white.svg',
    eswe: '/img/evus/eswe/logo.svg',
    default: '/img/default/logo.png',
    'powercloud-de': '/img/evus/powercloud-de/logo_white.svg',
    eworld: '/img/evus/eworld/logo.png',
    advizzo: '/img/evus/advizzo/logo.png',
    ewr: '/img/evus/ewr/logo_header.jpeg',
    hsg: '/img/evus/hsg/logo.svg',
};
