import Base from '@/mixins/base';
import { default as Component } from 'vue-class-component';
import WithRender from './usage-history-widget.html';

import Chart from '@/components/molecules/Chart.vue';

interface HistoryEntry {
    x: number;
    y: number;
    periodStart: number;
}

@WithRender
@Component({
    components: { Chart },
    props: {
        referenceValues: {
            type: Array,
            default: undefined,
        },
    },
})
export class UsageHistoryWidget extends Base {
    protected referenceValues?: number[];

    protected elements = {
        velbert: ['hide-history-legend'],
        hsg: ['hide-reference-explanation'],
        wechselstrom: ['hide-reference-explanation'],
    };

    get invoices(): any {
        return this.$store.getters['payment/getState'](
            this.$store.state.contracts.contractId
        ).invoices;
    }

    get referenceValuesEnabled(): boolean {
        return this.$store.state.settings.enableUsageHistoryReferenceValues;
    }

    get invoicesLoaded(): any {
        return this.$store.getters['payment/getState'](
            this.$store.state.contracts.contractId
        ).invoicesLoaded;
    }

    get chartColor() {
        const chartColorTable = {
            schweizstrom: '#cb0a20',
        };

        return (
            chartColorTable[process.env.VUE_APP_ENVIRONMENT as string] ??
            'var(--primary)'
        );
    }

    get usageHistory() {
        return this.invoices ?? [];
    }

    get usageHistorySeries(): HistoryEntry[] {
        const msPerYear = 1000 * 60 * 60 * 24 * 365;

        let prevEntry;

        return this.usageHistory.map((item) => {
            const start = new Date(item.periodStart).getTime(),
                end = new Date(item.periodEnd).getTime(),
                diff = Math.abs(end - start) / msPerYear,
                roundedDiff = Math.round(diff * 100) / 100,
                usage = parseFloat(item.usage);

            let entry = {
                periodStart: start,
                x: end,
                y: usage,
            };

            // stack two bill entries at the same position if
            // they were within the same year
            if (prevEntry) {
                entry = prevEntry;
                entry.periodStart = start;
                entry.y += usage;

                prevEntry = undefined;
            } else if (roundedDiff < 1) {
                prevEntry = entry;
            }

            return entry;
        });
    }
}
