import { default as Component } from 'vue-class-component';
import { ContentLoader } from 'vue-content-loader';
import WithRender from './invoice-widget.html';
import { Watch } from 'vue-property-decorator';
import { saveAs } from 'file-saver';

import * as Sentry from '@sentry/browser';

import { MessagesBlock } from '@/components/snippets/messages';

import IocContainer from '@/container/IocContainer';
import SERVICES from '@/container/Services';

import Auth from '@/interfaces/Auth';
import Payment from '@/interfaces/Payment';
import Format from '@/interfaces/Format';
import Tracking from '@/interfaces/Tracking';
import Base from '@/mixins/base';

const authProvider = IocContainer.get<Auth>(SERVICES.AUTH);
const paymentProvider = IocContainer.get<Payment>(SERVICES.PAYMENT);
const formatProvider = IocContainer.get<Format>(SERVICES.FORMAT);
const trackingProvider = IocContainer.get<Tracking>(SERVICES.TRACKING);

@WithRender
@Component({
    filters: {
        date: formatProvider.date(),
        euro: formatProvider.euro(),
    },
    components: {
        ContentLoader,
        'messages-block': MessagesBlock,
    },
})
export class InvoiceWidget extends Base {
    public showContent = true;
    private invoicesMapped = [];
    protected elements = {
        bsw: ['downloadInvoice-link', 'invoice-type', 'invoice-usage'],
        sachsenenergie: ['show-personal-info-link'],
        velbert: [
            'downloadInvoice-link',
            'table-fixed-columns',
            'hide-invoice-general-meter-read-info',
        ],
        sonnenwind: ['show-no-invoices-text'],
        wdenergy: ['show-no-invoices-text'],
        'wdenergy-us': ['show-no-invoices-text'],
        prokon: ['hide-content', 'show-more-button'],
        schweizstrom: ['show-invoice-explanation-link'],
    };

    private downloadErrors: Record<any, any> = [];

    protected created(): void {
        if (this.$store.state.contracts.contractId) {
            this.$store.dispatch(
                'payment/invoices',
                this.$store.state.contracts.contractId
            );
        }
    }

    private checkHasDownloadError(invoiceNumber: string) {
        return this.downloadErrors[invoiceNumber] == true;
    }

    private addDownloadError(invoiceNumber: string) {
        if (!this.downloadErrors[invoiceNumber]) {
            this.downloadErrors[invoiceNumber] = true;
        }
        this.$forceUpdate();
    }

    @Watch('invoices')
    protected onInvoicesChange(newVal): void {
        this.invoicesMapped = newVal.map((obj) => ({ ...obj, active: false }));
    }

    get invoiceShowUnsettledValue(): boolean {
        return this.$store.state.settings.invoiceWidgetShowUnsettledValue;
    }

    get invoices(): any {
        return this.$store.getters['payment/getState'](
            this.$store.state.contracts.contractId
        ).invoices;
    }

    get invoicesLoaded(): any {
        return this.$store.getters['payment/getState'](
            this.$store.state.contracts.contractId
        ).invoicesLoaded;
    }

    protected activate(item): void {
        item.active = !item.active;
    }

    protected changeShowContent(): void {
        this.showContent = !this.showContent;
    }

    protected mounted(): void {
        if (this.displayElement('hide-content')) {
            this.showContent = false;
        }
    }

    private downloadInvoice(invoice): void {
        if (this.checkHasDownloadError(invoice.invoiceNumber)) {
            return;
        }
        if (authProvider.isAuthenticated()) {
            paymentProvider
                .downloadInvoice(
                    this.$store.state.contracts.contractId,
                    parseInt(invoice.invoiceNumber, 10)
                )
                .catch((response) => {
                    this.addDownloadError(invoice.invoiceNumber);
                    return response;
                })
                .then(
                    async (response) => {
                        const isJsonBlob = (data) =>
                            data instanceof Blob &&
                            data.type === 'application/json';
                        const responseData = isJsonBlob(response?.data)
                            ? await response?.data?.text()
                            : response?.data || {};
                        const responseJson =
                            typeof responseData === 'string'
                                ? JSON.parse(responseData)
                                : responseData;
                        if (
                            responseJson &&
                            responseJson.success &&
                            responseJson.pdf
                        ) {
                            trackingProvider.downloadInvoice(
                                invoice.invoiceNumber
                            );
                            this.saveBlobAsFile(
                                responseJson.pdf,
                                responseJson.title + '.pdf',
                                'application/pdf'
                            );
                            return;
                        }
                    },
                    (error) => {
                        this.addDownloadError(invoice.invoiceNumber);
                        Sentry.captureException(new Error(error));
                    }
                );
        }
    }
}
