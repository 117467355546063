import { default as Component } from 'vue-class-component';
import WithRender from './post-box-combined-widget.html';
import { saveAs } from 'file-saver';
import { ContentLoader } from 'vue-content-loader';
import { MessagesBlock } from '@/components/snippets/messages';

import * as Sentry from '@sentry/browser';

import IocContainer from '../../../container/IocContainer';
import SERVICES from '../../../container/Services';
import Auth from '../../../interfaces/Auth';
import Format from '../../../interfaces/Format';
import Contracts from '../../../interfaces/Contracts';

const authProvider = IocContainer.get<Auth>(SERVICES.AUTH);
const contractProvider = IocContainer.get<Contracts>(SERVICES.CONTRACTS);
const formatProvider = IocContainer.get<Format>(SERVICES.FORMAT);
const trackingProvider = IocContainer.get<Tracking>(SERVICES.TRACKING);

import { ServiceWidget } from '../service-widget/service-widget';
import Tracking from '@/interfaces/Tracking';
import Base from '@/mixins/base';

@WithRender
@Component({
    components: {
        'service-widget': ServiceWidget,
        ContentLoader,
        'messages-block': MessagesBlock,
    },
    filters: {
        datetime: formatProvider.datetime(),
        date: formatProvider.date(),
    },
})
export class PostBoxCombinedWidget extends Base {
    protected elements = {
        velbert: [
            'envelop-default-icon',
            'bottom-timeline-marker',
            'loading-animation',
            'break-word',
            'date-only',
        ],
    };

    private communicationsLimit = 5;
    private communicationsOffset = 0;
    private downloadErrors: Record<any, any> = [];

    protected created() {
        this.$store.dispatch('customer/communications', {
            limit: this.communicationsLimit,
            offset: this.communicationsOffset,
        });
    }

    protected loadMore() {
        this.communicationsOffset =
            this.communicationsOffset + this.communicationsLimit;
        trackingProvider.postboxGetMore();
        this.$store.dispatch('customer/communications', {
            limit: this.communicationsLimit,
            offset: this.communicationsOffset,
        });
    }
    private errorMessageFromDownload: Record<any, any> = [];

    get communications() {
        return this.$store.state.customer.communications;
    }

    get communicationsErrorLoaded() {
        return this.$store.state.customer.communicationsErrorLoaded;
    }

    get communicationsLoading() {
        return !this.$store.state.customer.communicationsLoaded;
    }

    get serviceWidgetPosition() {
        if (this.communications.length % 2 === 0) {
            return 'm-timeline-1__item--left';
        } else {
            return 'm-timeline-1__item--right';
        }
    }

    get customerData() {
        return this.$store.state.customer;
    }

    get allLoaded() {
        if (this.communications.length === 0) {
            return true;
        }

        return (
            typeof this.communications === 'object' &&
            this.communications.filter((element) => {
                return element.timelineLast === true;
            }).length > 0
        );
    }

    private communicationTitle(communication) {
        if (this.currentClient === 'velbert') {
            if (communication.createdBy === '10') {
                return communication.title;
            } else {
                return (
                    communication.title +
                    ' ' +
                    this.$t('widget.pbc.contract') +
                    ' ' +
                    communication.contractId
                );
            }
        }
        if (this.customerData.isFleetCustomer) {
            return communication.title;
        }
        return (
            communication.title +
            ' ' +
            this.$t('widget.pbc.contract') +
            ' ' +
            communication.contractId
        );
    }

    private checkHasDownloadError(communicationId: string) {
        return this.downloadErrors[communicationId] == true;
    }

    private addDownloadError(communicationId: string) {
        if (!this.downloadErrors[communicationId]) {
            this.downloadErrors[communicationId] = true;
        }
        this.$forceUpdate();
    }

    private downloadCustomerCommunication(communication) {
        if (this.checkHasDownloadError(communication.communicationId)) {
            return false;
        }
        const title = this.removeUmlaut(communication.title.replace(/\s/g, ''));
        if (authProvider.isAuthenticated()) {
            contractProvider
                .downloadCustomerCommunication(
                    this.$store.state.contracts.contractId,
                    communication.communicationId,
                    title
                )
                .catch((response) => {
                    this.addDownloadError(communication.communicationId);
                    return response;
                })
                .then(
                    async (response) => {
                        const isJsonBlob = (data) =>
                            data instanceof Blob &&
                            data.type === 'application/json';
                        const responseData =
                            isJsonBlob(response?.data) &&
                            typeof response?.data?.text !== 'undefined'
                                ? await response?.data?.text()
                                : response?.data || {};
                        const responseJson =
                            typeof responseData === 'string'
                                ? JSON.parse(responseData)
                                : responseData;
                        if (
                            responseJson &&
                            responseJson.success &&
                            responseJson.pdf
                        ) {
                            trackingProvider.downloadCustomerCommunication(
                                title
                            );
                            this.saveBlobAsFile(
                                responseJson.pdf,
                                responseJson.title + '.pdf',
                                'application/pdf'
                            );
                            return;
                        }
                    },
                    (error) => {
                        this.addDownloadError(communication.communicationId);
                        Sentry.captureException(new Error(error));
                    }
                );
        }
    }
}
