import { ChangeCustomerDataWidgetElliGeneral } from '@/components/widgets/change-customer-data-widget/custom/elli-general';
import { ChangeCustomerDataWidgetWdenergy } from '@/components/widgets/change-customer-data-widget/custom/wdenergy';
import { ChangeCustomerDataWidgetWdenergyUs } from '@/components/widgets/change-customer-data-widget/custom/wdenergy-us';
import { ChangeCustomerDataWidgetSachsenenergie } from '@/components/widgets/change-customer-data-widget/custom/sachsenenergie';
import { ChangeCustomerDataWidgetVelbert } from '@/components/widgets/change-customer-data-widget/custom/velbert';
import { ChangeCustomerDataWidget } from '@/components/widgets/change-customer-data-widget/change-customer-data-widget';

let widget;

if (process.env.VUE_APP_ENVIRONMENT?.includes('elli')) {
    widget = ChangeCustomerDataWidgetElliGeneral;
} else if (process.env.VUE_APP_ENVIRONMENT === 'sachsenenergie') {
    widget = ChangeCustomerDataWidgetSachsenenergie;
} else if (process.env.VUE_APP_ENVIRONMENT === 'wdenergy') {
    widget = ChangeCustomerDataWidgetWdenergy;
} else if (process.env.VUE_APP_ENVIRONMENT === 'wdenergy-us') {
    widget = ChangeCustomerDataWidgetWdenergyUs;
} else if (process.env.VUE_APP_ENVIRONMENT === 'velbert') {
    widget = ChangeCustomerDataWidgetVelbert;
} else {
    widget = ChangeCustomerDataWidget;
}

export { widget as ChangeCustomerDataWidget };
