import Base from '@/mixins/base';
import { default as Component } from 'vue-class-component';
import WithRender from './thg-car-data-widget.html';
import { FormComponents } from '@/components/snippets/form-components';
import { MessagesBlock } from '@/components/snippets/messages';
import FileService from '@/services/FileService';
import { VehicleRegistration } from '@/providers/ContractsProvider';
import ImageModal from '@/components/molecules/image-modal.vue';
import LoadingSpinner from '@/components/molecules/loading-spinner.vue';

import iocContainer from '@/container/IocContainer';
import SERVICES from '@/container/Services';
import Files from '@/interfaces/Files';
import Contracts from '@/interfaces/Contracts';
import { Prop } from 'vue-property-decorator';
import { ThgBaseService } from '@/models/thg/abstract/ThgBaseService';

@WithRender
@Component({
    components: {
        'messages-block': MessagesBlock,
        'custom-input': FormComponents.CustomInput,
        'custom-label': FormComponents.CustomLabel,
        'form-group': FormComponents.FormGroup,
        'form-wrapper': FormComponents.FormWrapper,
        'custom-datetime': FormComponents.CustomDateTime,
        'custom-radio': FormComponents.CustomRadio,
        'custom-checkbox': FormComponents.CustomCheckbox,
        'image-modal': ImageModal,
        'loading-spinner': LoadingSpinner,
    },
})
export class ThgCarDataWidget extends Base {
    @Prop() public bonusYear!: string;
    @Prop() public thgService!: ThgBaseService;

    protected ApiProvider = iocContainer.get<Files>(SERVICES.FILES);

    protected contractProvider = iocContainer.get<Contracts>(
        SERVICES.CONTRACTS
    );

    public hasLoaded = false;
    public fileIsUploading = false;
    public filesAreSubmitting = false;
    public fieldsAreSubmitting = false;

    // testing options (not included in integration branch):
    public statusSwitchEnabled = false;
    public currentStatus = 'pending';
    public dialogOpen = true;

    public formFields: Record<string, string | null> = {
        licensePlate: null,
    };

    public vehicleRegistration: VehicleRegistration | null = {
        front: '',
        back: '',
    };

    public uploadedFiles: any[] = [];

    get currentState(): string {
        return this.thgService.getCurrentStateForYear(this.bonusYear);
    }

    get statusPending(): boolean {
        return this.thgService.isStatusPendingForYear(this.bonusYear);
    }

    get statusUnknown(): boolean {
        return this.thgService.isStatusUnknownForYear(this.bonusYear);
    }

    get isContractCancelled(): boolean {
        return this.contractStatus.statusCode >= 8000;
    }

    get vehicleRegistrationEmpty(): boolean {
        return (
            !this.vehicleRegistration ||
            !this.vehicleRegistration.front ||
            !this.vehicleRegistration.back
        );
    }

    get statusUnsuccessful(): boolean {
        return this.thgService.isStatusErrorForYear(this.bonusYear);
    }

    get statusSuccessful(): boolean {
        return (
            this.thgService.isStatusSentForYear(this.bonusYear) ||
            this.thgService.isStatusRejectedForYear(this.bonusYear) ||
            this.thgService.isStatusVerifiedForYear(this.bonusYear) ||
            this.thgService.isStatusConfirmedForYear(this.bonusYear)
        );
    }

    get vehicleId(): string {
        return this.thgService.getExtendedDataFieldValue(
            this.thgService.extendedDataKeyVehicleIds
        );
    }

    get licensePlate(): string {
        return this.thgService.getLicensePlate();
    }

    get vehicleClass(): string {
        const vehicleClass = this.thgService.getExtendedDataFieldValue(
            this.thgService.extendedDataKeyVehicleClasses
        );
        if (vehicleClass == '') {
            const vehicleClassWd = this.thgService.getExtendedDataFieldValue(
                this.thgService.extendedDataKeyVehicleClassesWorkDigital
            );
            return vehicleClassWd.toLowerCase();
        }
        return vehicleClass.toLowerCase();
    }

    public async getVehicleRegistration(year: string) {
        return await this.contractProvider.getVehicleRegistration(
            this.contractId as number,
            year
        );
    }

    public async updateVehicleRegistration() {
        const vehicleRegistration = await this.getVehicleRegistration(
            this.bonusYear
        );

        if (vehicleRegistration && vehicleRegistration.front) {
            this.vehicleRegistration = vehicleRegistration;
        }
    }

    public async mounted() {
        if (this.bonusYear) {
            this.updateVehicleRegistration();
        }

        this.formFields.licensePlate = this.licensePlate;

        if (this.statusUnsuccessful) {
            this.currentStatus = 'unsuccessful';
        } else if (this.statusSuccessful) {
            this.currentStatus = 'successful';
        } else {
            this.currentStatus = 'pending';
        }

        this.hasLoaded = true;
    }

    public submitFiles() {
        if (this.fileIsUploading || this.uploadedFiles.length !== 1) {
            return;
        }

        this.filesAreSubmitting = true;

        const vm = this;

        this.ApiProvider.uploadFileViaS3({
            files: this.uploadedFiles,
            contractId: this.contractId,
            year: this.bonusYear,
        })
            .then((res) => {
                if (res.data.success)
                    this.$store.dispatch('contracts/contracts');
            })
            .finally(() => (vm.filesAreSubmitting = false));
    }

    public submitFields() {
        this.fieldsAreSubmitting = true;

        const vm = this;

        this.contractProvider
            .updateCarData({
                contractId: this.contractId,
                licensePlate: this.formFields.licensePlate,
                year: this.bonusYear,
            })
            .then(async () => {
                this.hasLoaded = false;
                await vm.$store.dispatch('contracts/contracts');
                await vm.updateVehicleRegistration();
                this.hasLoaded = true;
            })
            .finally(() => (vm.fieldsAreSubmitting = false));
    }

    protected onFileSelect(event: Record<any, any>) {
        const file: File = event.target.files[0];
        if (
            ['image/svg', 'image/svg+xml'].some((type) =>
                type.includes(file.type)
            )
        ) {
            event.target.value = '';
            return;
        }

        this.fileIsUploading = true;
        const side: string = event.target.getAttribute('data-side');
        const fileService = new FileService(side, file);

        const vm = this;

        fileService
            .upload()
            .then((res): void => {
                this.uploadedFiles.push(res);
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => (vm.fileIsUploading = false));

        return true;
    }

    protected handleUploadError(err, side): void {
        if (err.message && err.message === 'FileTooBig') side.sizeError = true;
    }
}
