export const productImageMap = {
    berlinStrom_Kultur_2019:
        '/img/evus/bsw/products/Label_berlinStrom_Kultur.svg',
    berlinStrom_Kultur_2019_01:
        '/img/evus/bsw/products/Label_berlinStrom_Kultur.svg',
    berlinStrom_Kultur_20:
        '/img/evus/bsw/products/Label_berlinStrom_Kultur.svg',

    berlinStrom_Sonne_19:
        '/img/evus/bsw/products/Label_berlinStrom_Sonneplus.svg',
    berlinStrom_Sonne_19_temp:
        '/img/evus/bsw/products/Label_berlinStrom_Sonneplus.svg',
    berlinStrom_Sonne_20:
        '/img/evus/bsw/products/Label_berlinStrom_Sonneplus.svg',
    berlinStrom_Sonne_20_temp:
        '/img/evus/bsw/products/Label_berlinStrom_Sonneplus.svg',
    berlinStrom_Sonne_20_2HJ:
        '/img/evus/bsw/products/Label_berlinStrom_Sonneplus.svg',
    berlinStrom_Sonne_20_2HJ_temp:
        '/img/evus/bsw/products/Label_berlinStrom_Sonneplus.svg',
    berlinStrom_Sonne_21:
        '/img/evus/bsw/products/Label_berlinStrom_Sonneplus.svg',
    berlinStrom_Sonne_21_temp:
        '/img/evus/bsw/products/Label_berlinStrom_Sonneplus.svg',
    berlinStrom_Oeko_Flex_Gewerbe_ps:
        '/img/evus/bsw/products/Label_berlinStrom_OekoFlex-Gewerbe_rgb.svg',

    'SM-0010-200020': '/img/evus/bsw/products/Label_berlinStrom_Waermeplus.svg',
    'SK-9999-600001': '/img/evus/bsw/products/Label_berlinStrom_Waermeplus.svg',
    'SM-0010-200014': '/img/evus/bsw/products/Label_berlinStrom_Waermeplus.svg',
    'SM-0010-200013': '/img/evus/bsw/products/Label_berlinStrom_Waermeplus.svg',
    'SM-0010-200012': '/img/evus/bsw/products/Label_berlinStrom_Waermeplus.svg',
    'SM-0010-200011': '/img/evus/bsw/products/Label_berlinStrom_Waermeplus.svg',
    'SM-0010-200010': '/img/evus/bsw/products/Label_berlinStrom_Waermeplus.svg',
    'SM-0010-20009': '/img/evus/bsw/products/Label_berlinStrom_Waermeplus.svg',
    'SM-0010-20008': '/img/evus/bsw/products/Label_berlinStrom_Waermeplus.svg',
    'SM-0010-20007': '/img/evus/bsw/products/Label_berlinStrom_Waermeplus.svg',
    'SM-0010-20006': '/img/evus/bsw/products/Label_berlinStrom_Waermeplus.svg',
    'SM-0010-20005': '/img/evus/bsw/products/Label_berlinStrom_Waermeplus.svg',
    'SM-0010-20004': '/img/evus/bsw/products/Label_berlinStrom_Waermeplus.svg',
    'SM-0010-20003': '/img/evus/bsw/products/Label_berlinStrom_Waermeplus.svg',
    'SM-0010-20002': '/img/evus/bsw/products/Label_berlinStrom_Waermeplus.svg',
    'SM-0010-20001': '/img/evus/bsw/products/Label_berlinStrom_Waermeplus.svg',
    berlinStrom_Waerme_19:
        '/img/evus/bsw/products/Label_berlinStrom_Waermeplus.svg',
    berlinStrom_Waerme_19_temp:
        '/img/evus/bsw/products/Label_berlinStrom_Waermeplus.svg',
    berlinStrom_Waerme_20:
        '/img/evus/bsw/products/Label_berlinStrom_Waermeplus.svg',
    berlinStrom_Waerme_20_temp:
        '/img/evus/bsw/products/Label_berlinStrom_Waermeplus.svg',
    berlinStrom_Waerme_20_2HJ:
        '/img/evus/bsw/products/Label_berlinStrom_Waermeplus.svg',
    berlinStrom_Waerme_20_2HJ_temp:
        '/img/evus/bsw/products/Label_berlinStrom_Waermeplus.svg',
    berlinStrom_Waerme_21:
        '/img/evus/bsw/products/Label_berlinStrom_Waermeplus.svg',
    berlinStrom_Waerme_21_temp:
        '/img/evus/bsw/products/Label_berlinStrom_Waermeplus.svg',

    berlinStrom_Oeko_20: '/img/evus/bsw/products/Label_berlinStrom.svg',

    'berlinStrom_Oeko_Treue12.24_ps_test':
        '/img/evus/bsw/products/Label_berlinStrom_OekoTreue12.24.svg',
    'berlinStrom_Oeko_Treue12.24_ps':
        '/img/evus/bsw/products/Label_berlinStrom_OekoTreue12.24.svg',
    'brandenburgStrom_Oeko_Treue12.24_ps_test':
        '/img/evus/bsw/products/Label_brandenburgStrom_OekoTreue12.24.svg',
    'brandenburgStrom_Oeko_Treue12.24_ps':
        '/img/evus/bsw/products/Label_brandenburgStrom_OekoTreue12.24.svg',

    'berlinStrom_Oeko_Treue12.25_ps':
        '/img/evus/bsw/products/Label_berlinStrom_OekoTreue12.25.svg',
    'brandenburgStrom_Oeko_Treue12.25_ps':
        '/img/evus/bsw/products/Label_brandenburgStrom_OekoTreue12.25.svg',
    'quartierStrom_Oeko_Treue12.25_ps':
        '/img/evus/bsw/products/QuartierStrom_rgb.svg',

    brandenburgStrom_Basis19:
        '/img/evus/bsw/products/Label_brandenburgStrom.svg',
    brandenburgStrom_Basis20:
        '/img/evus/bsw/products/Label_brandenburgStrom.svg',
    brandenburgStrom_Basis_20_2HJ:
        '/img/evus/bsw/products/Label_brandenburgStrom.svg',

    brandenburgstrom_Oeko_12_2:
        '/img/evus/bsw/products/Label_brandenburgstrom_Oeko_12_2.svg',

    'SB-1010-600002': '/img/evus/bsw/products/Label_QuartierStrom.svg',
    'SB-1100-600002': '/img/evus/bsw/products/Label_QuartierStrom.svg',
    'SB-1060-600002': '/img/evus/bsw/products/Label_QuartierStrom.svg',
    'SB-1050-600002': '/img/evus/bsw/products/Label_QuartierStrom.svg',
    'SB-1040-600002': '/img/evus/bsw/products/Label_QuartierStrom.svg',
    'SB-1030-600002': '/img/evus/bsw/products/Label_QuartierStrom.svg',
    'SB-1020-600002': '/img/evus/bsw/products/Label_QuartierStrom.svg',
    Quartierstrom_Neptun_19: '/img/evus/bsw/products/Label_QuartierStrom.svg',
    Quartierstrom_Neptun_20_temp:
        '/img/evus/bsw/products/Label_QuartierStrom.svg',
    Quartierstrom_Neptun_20: '/img/evus/bsw/products/Label_QuartierStrom.svg',
    Quartierstrom_20: '/img/evus/bsw/products/Label_QuartierStrom.svg',
    Quartierstrom_Neptun_20_2HJ:
        '/img/evus/bsw/products/Label_QuartierStrom.svg',
    Quartierstrom_Neptun_20_2HJ_temp:
        '/img/evus/bsw/products/Label_QuartierStrom.svg',
    Quartierstrom_20_2HJ: '/img/evus/bsw/products/Label_QuartierStrom.svg',
    Quartierstrom_21: '/img/evus/bsw/products/Label_QuartierStrom.svg',
    Quartierstrom_Neptun_21_temp:
        '/img/evus/bsw/products/Label_QuartierStrom.svg',
    Quartierstrom_Neptun_21: '/img/evus/bsw/products/Label_QuartierStrom.svg',
    Quartierstrom_21_2: '/img/evus/bsw/products/Label_QuartierStrom.svg',
    Quartierstrom_21_2_temp: '/img/evus/bsw/products/Label_QuartierStrom.svg',

    'SM-1010-300001':
        '/img/evus/bsw/products/Label_brandenburgStrom_Oeko12.svg',
    'SK-1010-600001':
        '/img/evus/bsw/products/Label_brandenburgStrom_Oeko12.svg',
    brandenburgStrom_Oeko_12:
        '/img/evus/bsw/products/Label_brandenburgStrom_Oeko12.svg',
    brandenburgStrom_Oeko_12_2:
        '/img/evus/bsw/products/Label_brandenburgStrom_Oeko12.svg',

    brandenburgStrom_Oeko_24:
        '/img/evus/bsw/products/Label_brandenburgStrom_Oeko24.svg',
    brandenburgStrom_Oeko_24_2:
        '/img/evus/bsw/products/Label_brandenburgStrom_Oeko24.svg',

    berlinStrom_Oeko_12: '/img/evus/bsw/products/Label_berlinStrom_Oeko12.svg',
    berlinStrom_Oeko_12_V10_21:
        '/img/evus/bsw/products/Label_berlinStrom_Oeko12.svg',
    berlinStrom_Oeko_12_ps:
        '/img/evus/bsw/products/Label_berlinStrom_Oeko12.svg',

    berlinStrom_Oeko_12_2:
        '/img/evus/bsw/products/Label_berlinStrom_Oeko12_2.svg',

    berlinStrom_Oeko_24: '/img/evus/bsw/products/Label_berlinStrom_Oeko24.svg',
    berlinStrom_Oeko_24_V10_21:
        '/img/evus/bsw/products/Label_berlinStrom_Oeko24.svg',
    berlinStrom_Oeko_24_ps:
        '/img/evus/bsw/products/Label_berlinStrom_Oeko24.svg',
    berlinStrom_Oeko_24_Gewerbe_ps:
        '/img/evus/bsw/products/Label_berlinStrom_Oeko24-Gewerbe_rgb.svg',
    berlinStrom_Oeko_12_Gewerbe_ps:
        '/img/evus/bsw/products/Label_berlinStrom_Oeko12-Gewerbe_rgb.svg',

    berlinStrom_Oeko_21: '/img/evus/bsw/products/Label_berlinStrom_Oeko21.svg',

    berlinStrom_Oeko_21_2:
        '/img/evus/bsw/products/Label_berlinStrom_Oeko_21_2.svg',

    berlinStrom_Oeko_Flex_21:
        '/img/evus/bsw/products/Label_berlinStrom_Oeko_Flex_21.svg',
    berlinStrom_Oeko_Flex_ps:
        '/img/evus/bsw/products/Label_berlinStrom_Oeko_Flex_21.svg',
    brandenburgStrom_Oeko_24_ps:
        '/img/evus/bsw/products/Label_brandenburgStrom_Oeko24.svg',
    brandenburgStrom_Oeko_24_Gewerbe_ps:
        '/img/evus/bsw/products/Label_brandenburgStrom_Oeko24-Gewerbe_rgb.svg',
    brandenburgStrom_Oeko_12_Gewerbe_ps:
        '/img/evus/bsw/products/Label_brandenburgStrom_Oeko12-Gewerbe_rgb.svg',

    'SK-5010-600003': '/img/evus/bsw/products/Label_berlinStrom_Gewerbe.svg',

    'SK-0020-600001': '/img/evus/bsw/products/Label_berlinStrom_Basis.svg',
    'SK-0030-600001': '/img/evus/bsw/products/Label_berlinStrom_Basis.svg',
    'SK-0010-600001': '/img/evus/bsw/products/Label_berlinStrom_Basis.svg',
    berlinStrom_Basis_2019:
        '/img/evus/bsw/products/Label_berlinStrom_Basis.svg',
    XXX_berlinStrom_Basis_2019_OLD:
        '/img/evus/bsw/products/Label_berlinStrom_Basis.svg',
    berlinStrom_Basis_20: '/img/evus/bsw/products/Label_berlinStrom_Basis.svg',
    berlinStrom_Basis_20_2HJ:
        '/img/evus/bsw/products/Label_berlinStrom_Basis.svg',
    berlinStrom_Sonne_Plus_ps:
        '/img/evus/bsw/products/Label_berlinStrom_Sonneplus.svg',

    berlinStrom_Oeko_Flex_V10_21:
        '/img/evus/bsw/products/Label_berlinStrom_OekoFlex.svg',
    berlinStrom_Sonne_Plus_temp_ps:
        '/img/evus/bsw/products/Label_berlinStrom_Sonneplus.svg',
    berlinStrom_Waerme_Plus_ps:
        '/img/evus/bsw/products/Label_berlinStrom_Waermeplus.svg',
    berlinStrom_Waerme_Plus_ps_temp:
        '/img/evus/bsw/products/Label_berlinStrom_Waermeplus.svg',
    Quartierstrom_22_ps: '/img/evus/bsw/products/Label_QuartierStrom.svg',
    Quartierstrom_22_temp_ps: '/img/evus/bsw/products/Label_QuartierStrom.svg',
    Quartierstrom_Oeko_ps: '/img/evus/bsw/products/Label_QuartierStrom.svg',
    berlinStrom_Oeko_Treue24:
        '/img/evus/bsw/products/Label_berlinStrom_OekoTreue24_rgb.svg',
    berlinStrom_Oeko_Treue12_ps:
        '/img/evus/bsw/products/Label_berlinStrom_OekoTreue12_rgb.svg',
    brandenburgStrom_Oeko_Treue12_ps:
        '/img/evus/bsw/products/Label_brandenburgStrom_OekoTreue12_rgb.svg',
};
