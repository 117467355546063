import { AuthPage as AuthPageParent } from './auth-page';
import { AuthPageQcells } from './custom/qcells';
import { AuthPageProkon } from './custom/prokon';
import { AuthPageVelbert } from './custom/velbert';
import { AuthPageSchweizstrom } from './custom/schweizstrom';
import { AuthPageAhrensburg } from './custom/ahrensburg';
import { AuthPageElli } from './custom/elli';
import { AuthPageWdenergy } from './custom/wdenergy';
import { AuthPageWdenergyUs } from './custom/wdenergy-us';

export const AuthPage =
    {
        qcells: AuthPageQcells,
        prokon: AuthPageProkon,
        velbert: AuthPageVelbert,
        schweizstrom: AuthPageSchweizstrom,
        elli: AuthPageElli,
        'elli-thg-audi': AuthPageElli,
        'elli-thg-cupra': AuthPageElli,
        'elli-thg-dealer': AuthPageElli,
        'elli-thg-skoda': AuthPageElli,
        'elli-thg-vw': AuthPageElli,
        'elli-thg-vwfs': AuthPageElli,
        'elli-thg-freenet': AuthPageElli,
        'elli-thg-elli': AuthPageElli,
        'elli-thg-man': AuthPageElli,
        sonnenwind: AuthPageWdenergy,
        wdenergy: AuthPageWdenergy,
        'wdenergy-us': AuthPageWdenergyUs,
        'dsp-ahrensburg': AuthPageAhrensburg,
    }[process.env.VUE_APP_ENVIRONMENT!] || AuthPageParent;
