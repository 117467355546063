import Vue from 'vue';
import { injectable } from 'inversify';
import axios, { AxiosResponse } from 'axios';
import Auth from '@/interfaces/Auth';
import PrototypeProvider from '@/providers/PrototypeProvider';

@injectable()
export default class AuthProvider extends PrototypeProvider implements Auth {
    constructor() {
        super();

        const headers = {
            Accept: 'application/json',
            Authorization: this.getApiAuth(),
            'WD-PORTAL-LANG': this.translationProvider.getCurrentLocale(),
            'WD-OVERWRITE-POWERCLOUD-APIS':
                process.env.VUE_APP_OVERWRITE_POWERCLOUD_APIS === 'true',
        };

        if (window.Cypress) {
            headers['IS-CYPRESS'] = 'yes';
        }

        this.http = axios.create({
            baseURL: process.env.VUE_APP_API_URL + '/v1/',
            headers: headers,
        });

        this.http.interceptors.response.use(
            (response) => response,
            this.responseErrorHandler
        );
    }

    public async prepareRegistration(
        customerId: string
    ): Promise<AxiosResponse> {
        return await this.http.post('/registration/prepare', {
            customerId,
        });
    }

    public async login(
        username: string,
        password: string,
        captchaToken = null
    ): Promise<AxiosResponse> {
        return await this.http.post('/login', {
            username,
            password,
            captchaToken,
        });
    }

    public async onetimePasswordPrepare(
        username: string,
        captchaToken = null
    ): Promise<AxiosResponse> {
        return await this.http.post('/login/onetime/prepare', {
            username,
            captchaToken,
        });
    }

    public async onetimePasswordAlternative(
        customerNumber: string,
        contractNumber: string,
        emailAddress?: string | null,
        iban?: string | null,
        captchaToken?: string | null
    ): Promise<AxiosResponse> {
        return await this.http.post('/login/onetime/alternative', {
            customerNumber,
            contractNumber,
            emailAddress,
            iban,
            captchaToken,
        });
    }

    public async onetimePasswordAlternativePrepare(
        customerNumber: string,
        contractNumber: string,
        captchaToken: null
    ): Promise<AxiosResponse> {
        return await this.http.post('/login/onetime/alternativePrepare', {
            customerNumber,
            contractNumber,
            captchaToken,
        });
    }

    public async onetimePasswordLogin(
        username: string,
        password: string
    ): Promise<AxiosResponse> {
        return await this.http.post('/login/onetime', { username, password });
    }

    public async getSecretField(
        contractId: number | null,
        customerId: number | null,
        email: string | null,
        captchaToken = null
    ): Promise<AxiosResponse> {
        let params = '';
        if (contractId) {
            params = '?contractId=' + contractId;
        } else if (customerId) {
            params = '?customerId=' + customerId;
        } else if (email) {
            params = '?email=' + email;
        }
        if (captchaToken) {
            params = params + '&captchaToken=' + captchaToken;
        }
        return await this.http.get('/secret-field/register' + params);
    }

    public async checkSecretField(
        contractId: number | null,
        customerId: number | null,
        secretFieldType: string,
        secretField: string
    ): Promise<AxiosResponse> {
        let params = '';
        if (contractId) {
            params = '?contractId=' + contractId;
        } else if (customerId) {
            params = '?customerId=' + customerId;
        }
        params +=
            '&secretFieldType=' +
            secretFieldType +
            '&secretField=' +
            secretField;
        return await this.http.get('/secret-field/check' + params);
    }

    public async updateLogin(
        contractId: number | null,
        customerId: number | null,
        secretField: string,
        email: string | null,
        username: string,
        password: string,
        passwordConfirmation: string,
        customerIdUrl: string | null
    ): Promise<AxiosResponse> {
        return await this.http.post('/login/update', {
            contractId,
            customerId,
            secretField,
            email,
            username,
            password,
            password_confirmation: passwordConfirmation,
            customerIdUrl,
        });
    }

    public async setPassword(
        hash: string,
        username: string,
        password: string,
        passwordConfirmation: string
    ): Promise<AxiosResponse> {
        return await this.http.post('/password/reset-password', {
            hash,
            username,
            password,
            passwordConfirmation,
        });
    }

    public async setPasswordReset(
        username: string,
        email: string,
        route: string
    ): Promise<AxiosResponse> {
        return await this.http.post('/password/reset', {
            username,
            email,
            route,
        });
    }

    public logout(idpRedirect: string): any {
        this.logoutCall().then(() => {
            this.sessionstorage.clear();
            Vue.$cookies.remove('SUPER-ADMIN-TOKEN');
            if (idpRedirect) {
                window.location.href = idpRedirect;
            } else {
                window.location.href = '/';
            }
        });
    }

    protected async logoutCall(): Promise<AxiosResponse> {
        return await this.http.post('/logout', {
            token: this.sessionstorage.getItem('token'),
        });
    }

    public async changePassword(
        customerId: number | null,
        oldPassword: string,
        newPassword: string,
        newConfirmPassword: string,
        totpCode: string | null
    ): Promise<AxiosResponse> {
        return await this.http.post(
            '/password/change',
            {
                customerId,
                oldPassword,
                newPassword,
                newConfirmPassword,
                totpCode,
            },
            {
                headers: {
                    Accept: 'application/json',
                    'WD-PORTAL-AUTH': this.sessionstorage.getItem('token'),
                    'WD-PORTAL-LANG':
                        this.translationProvider.getCurrentLocale(),
                    Authorization: this.getApiAuth(),
                },
            }
        );
    }

    public authenticated(token: string): any {
        this.sessionstorage.setItem('token', token);
    }

    public isAuthenticated(): boolean {
        const storageToken = this.sessionstorage.getItem('token');
        const cookieToken = window.Cypress
            ? 'CYPRESS'
            : Vue.$cookies.get('SUPER-ADMIN-TOKEN');

        return storageToken || cookieToken;
    }

    public token(): string | null {
        return this.sessionstorage.getItem('token');
    }

    public async idpCheckToken(data): Promise<AxiosResponse> {
        return await this.http.post('/auth/idp', data, {
            headers: {
                Accept: 'application/json',
                'WD-PORTAL-AUTH': this.sessionstorage.getItem('token'),
                'WD-PORTAL-LANG': this.translationProvider.getCurrentLocale(),
                Authorization: this.getApiAuth(),
            },
        });
    }

    public async superCheckAuthToken(data): Promise<AxiosResponse> {
        return await this.http.post('/super-check-auth-token', data, {
            headers: {
                Accept: 'application/json',
                Authorization: this.getApiAuth(),
            },
        });
    }

    public async checkAdminToken(data): Promise<AxiosResponse> {
        return await this.http.post('/check-admin', data, {
            headers: {
                Accept: 'application/json',
                Authorization: this.getApiAuth(),
            },
        });
    }

    public async setUsernameReset(
        email: string,
        route: string
    ): Promise<AxiosResponse> {
        return await this.http.post('/username/reset', {
            email,
            route,
        });
    }

    public async setUsername(
        hash: string,
        email: string,
        username: string
    ): Promise<AxiosResponse> {
        return await this.http.post('/username/reset-username', {
            hash,
            email,
            username,
        });
    }

    public async twoFactorAuthenticationIsActivated(): Promise<AxiosResponse> {
        return await this.http.get('/2fa/is-activated', {
            headers: {
                Accept: 'application/json',
                'WD-PORTAL-AUTH': this.sessionstorage.getItem('token'),
                'WD-PORTAL-LANG': this.translationProvider.getCurrentLocale(),
                Authorization: this.getApiAuth(),
            },
        });
    }

    public async twoFactorAuthenticationDisable(data: {
        code: string;
    }): Promise<AxiosResponse> {
        return await this.http.post('/2fa/disable', data, {
            headers: {
                Accept: 'application/json',
                'WD-PORTAL-AUTH': this.sessionstorage.getItem('token'),
                'WD-PORTAL-LANG': this.translationProvider.getCurrentLocale(),
                Authorization: this.getApiAuth(),
            },
        });
    }

    public async twoFactorAuthenticationEnable(): Promise<AxiosResponse> {
        return await this.http.post(
            '/2fa/enable',
            {},
            {
                headers: {
                    Accept: 'application/json',
                    'WD-PORTAL-AUTH': this.sessionstorage.getItem('token'),
                    'WD-PORTAL-LANG':
                        this.translationProvider.getCurrentLocale(),
                    Authorization: this.getApiAuth(),
                },
            }
        );
    }

    public async twoFactorAuthenticationActivate(data: {
        code: string;
    }): Promise<AxiosResponse<any>> {
        return await this.http.post('/2fa/activate', data, {
            headers: {
                Accept: 'application/json',
                'WD-PORTAL-AUTH': this.sessionstorage.getItem('token'),
                'WD-PORTAL-LANG': this.translationProvider.getCurrentLocale(),
                Authorization: this.getApiAuth(),
            },
        });
    }

    public async twoFactorAuthenticationCheckCode(data: {
        username: string;
        code: string;
    }): Promise<AxiosResponse<any>> {
        return await this.http.post('/2fa/check', data);
    }

    public async twoFactorAuthenticationRecovery(data: {
        username: string;
        email: string;
        codes: [];
    }): Promise<AxiosResponse<any>> {
        return await this.http.post('/2fa/recovery', data);
    }

    public async updateAuthPlusPassword(data: {
        username: string;
        password: string;
        confirmPassword: string;
        totpCode: string;
    }): Promise<AxiosResponse<any>> {
        return await this.http.post('auth-plus/update-password', data);
    }
}
