var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "lds-ring",
      class: { center: _vm.center },
      style: _vm.cssVars,
    },
    [_c("div"), _c("div"), _c("div"), _c("div")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }